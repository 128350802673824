/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
    { id: "en", name: "English - LTR", direction: "ltr" },
    { id: "es", name: "Español", direction: "ltr" },
    { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const firebaseConfig = {
    apiKey: "AIzaSyC_ftliDsusFrVUQveMsJFAyw3mWN96cnM",
    authDomain: "envoyluxe-c85bf.firebaseapp.com",
    databaseURL: "https://envoyluxe-c85bf-default-rtdb.firebaseio.com/",
    projectId: "envoyluxe-c85bf",
    storageBucket: "envoyluxe-c85bf.appspot.com",
    messagingSenderId: "363332302211",
    appId: "1:363332302211:web:0ba69129e6e5a56bc7b2b6",
    measurementId: "G-8HMB3M2XJE"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color"
export const isMultiColorActive = false;
export const defaultColor = "light.purple";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = true;
export const CURRENCY_UNIT = "$";

// ONESIGNAL_APP_ID
export const ONESIGNAL_APP_ID = "0a91d394-65d3-4b1c-bcea-a0555f3705d2"

// ORDER STATUS
export const Pending = "Pending"
export const InitialReview = "Initial Review"
export const ClientReview = "Client Review"
export const Procurement = "Procurement"
export const GiftWrapping = "Gift Wrapping"
export const Delivered = "Delivered"
export const Cancelled = "Cancelled"