import { CATEGORIES_LIST } from "../redux/actions";
import { auth, firestore, firebase } from "./Firebase"
import { isEmptyString } from "./Utils";
const ORDER_LIMIT = 15
const USERS_LIMIT = 15
const categoriesCollection = firestore.collection('CATEGORIES');
const delLocationsCollection = firestore.collection('DELIVERY_LOCATIONS');
const usersCollection = firestore.collection('USERS');
const ordersCollection = firestore.collection('ORDERS');
const statsCollection = firestore.collection('STATISTICS');
const adminCollection = firestore.collection('ADMINS');
const currentTimestamp = new Date()
export default class FSDBHandler {
  async adminLogin(email, password, onCompleted) {
    let adminData = {}
    const docs = adminCollection
      .where("email", "==", email)
      .where("password", "==", password)
    const adminDoc = await docs.get();
    if (adminDoc.empty) {
      console.log("====adminDoc.empty", adminDoc.empty)
      onCompleted(false)
    } else {
      adminDoc.forEach(doc => {
        adminData.adminId = doc.id
        adminData.email = doc.data().email
      })
      console.log("====adminData", adminData)
      onCompleted(adminData)
    }
  }
  //////// CATEGORIES FUNCTIONS
  async getCategoriesData(onCompleted) {
    let categories = []
    const categoryRef = categoriesCollection.orderBy('timestamp', 'desc')
    const categoryDoc = await categoryRef.get();
    if (categoryDoc.empty) {
      onCompleted(null)
    } else {
      categoryDoc.forEach(doc => {
        categories.push({ catId: doc.id, catName: doc.data().catName, type: doc.data().type })
      })
      onCompleted(categories)
    }
  }

  async addNewCategory(data, onCompleted) {
    // data = {
    //     "catName": "Gift EnVoy Test",
    //     "type": "gift"
    // }
    data.timestamp = currentTimestamp
    categoriesCollection.add(data)
      .then(async (response) => {
        await this.updateCatsStats("", (onresponse) => { })
        onCompleted(response)
      })
      .catch((error) => {
        onCompleted(null)
        console.log(error)
      })
  }

  async deleteCategory(catId, onCompleted) {
    await categoriesCollection.doc(catId)
      .delete()
      .then(async (response) => {
        await this.updateCatsStats("decrement", (onresponse) => { })
        onCompleted(true)
      })
      .catch((error) => {
        onCompleted(false)
        console.log(error)
      })
  }

  //////// DELIVERY LOCATIONS FUNCTIONS
  async getDeliveryLocations(onCompleted) {
    let data = []
    const categoryRef = delLocationsCollection.orderBy('timestamp', 'desc')
    const categoryDoc = await categoryRef.get();
    if (categoryDoc.empty) {
      onCompleted(null)
    } else {
      categoryDoc.forEach(doc => {
        let data1 = doc.data()
        data1.id = doc.id
        data.push(data1)
      })
      onCompleted(data)
    }
  }
  async addDeliveryLocation(data, onCompleted) {
    // data = {
    //     "county": "FairFax",
    //     "state": "VA",
    //     "zipCode": "64200",
    // }
    data.timestamp = currentTimestamp
    delLocationsCollection.add(data)
      .then(async (response) => {
        await this.updateCatsStats("", (onresponse) => { })
        onCompleted(response)
      })
      .catch((error) => {
        onCompleted(null)
        console.log(error)
      })
  }

  async deleteDeliveryLocation(id, onCompleted) {
    await delLocationsCollection.doc(id)
      .delete()
      .then(async (response) => {
        await this.updateCatsStats("decrement", (onresponse) => { })
        onCompleted(true)
      })
      .catch((error) => {
        onCompleted(false)
        console.log(error)
      })
  }

  //////// USERS FUNCTIONS
  async getSingleUserData(id, onCompleted) {
    const usersRef = usersCollection.doc(id)
    const usersDoc = await usersRef.get();
    if (usersDoc.exists) {
      onCompleted(usersDoc.data())
    } else {
      onCompleted(null)
    }
  }
  async getSingleAdminData(id, onCompleted) {
    let adminData = {}
    const usersRef = usersCollection.doc(id)
    const usersDoc = await usersRef.get();
    if (usersDoc.exists) {
      adminData = usersDoc.data()
      if (adminData.isAdmin) {
        onCompleted(usersDoc.data())
      } else {
        onCompleted(null)
      }
    } else {
      onCompleted(null)
    }
  }

  async changeAuthEmail(newEmail, onResponse) {
    // auth.signInWithEmailAndPassword('jimmyedward08@gmail.com', '123456')
    //     .then(function (userCredential) {
    //         userCredential.user.updateEmail(newEmail)
    //     })

    // var user = auth.currentUser
    // console.log("====iiii", user)
    // user.updateEmail(newEmail).then(() => {
    //     console.log("Email updated!");
    // }).catch((error) => { console.log(error); });
  }

  async getUsersList(lastLoadedDoc, onLoaded) {
    // const usersRef = usersCollection.orderBy('createdAt', 'asc')
    // const usersDoc = await usersRef.get();
    // if (usersDoc.empty) {
    //     onCompleted(null)
    // } else {
    //     usersDoc.forEach(doc => {
    //         let userData = doc.data()
    //         userData.userId = doc.id
    //         users.push(userData)
    //     })
    //     onCompleted(users)
    // }

    var snapshotDoc = await usersCollection
      .orderBy("createdAt", "desc")
    if (lastLoadedDoc) {
      snapshotDoc = await snapshotDoc.startAfter(lastLoadedDoc)
    }
    snapshotDoc = await snapshotDoc.limit(USERS_LIMIT)
    await snapshotDoc.get().then((docSnap) => {
      let usersData = [], lastVisDoc = null
      if (docSnap && docSnap.docs.length > 0) {
        lastVisDoc = docSnap.docs[docSnap.docs.length - 1]
        docSnap.forEach((doc) => {
          let usersObj = doc.data()
          usersObj.userId = doc.id
          usersData.push(usersObj)
        })
      }
      if (usersData.length < USERS_LIMIT) {
        onLoaded(usersData, lastVisDoc, false)
      } else {
        this.isMoreUsersExist(lastVisDoc, (isMoreExist) => {
          onLoaded(usersData, lastVisDoc, isMoreExist)
        })
      }
    }).catch((error) => {
      console.log("===error===", error)
      onLoaded([], null, false)
    })
  }
  async isMoreUsersExist(lastVisDoc, onLoaded) {
    var snapshotDoc = await usersCollection
      .orderBy("createdAt", "desc")
    if (lastVisDoc) {
      snapshotDoc = await snapshotDoc.startAfter(lastVisDoc)
    }
    let moreData = await snapshotDoc.limit(1).get()
    onLoaded((moreData && moreData.size >= 1))
  }

  async deleteUsers(userId, onCompleted) {
    await usersCollection.doc(userId)
      .delete()
      .then((response) => {
        auth.deleteUser(userId)
          .then(() => {
            onCompleted(true)
            console.log('Successfully deleted user');
          })
          .catch((error) => {
            onCompleted(false)
            console.log('Error deleting user:', error);
          });
      })
      .catch((error) => {
        onCompleted(false)
        console.log(error)
      })
  }

  async disableUser(uid) {
    auth.updateUser(uid, { disabled: true })
  }
  async updateAuthUser(uId, data, onResponse) {
    // data = {
    //     email: 'modifiedUser@example.com',
    //     phoneNumber: '+11234567890',
    //     emailVerified: true,
    //     password: 'newPassword',
    //     displayName: 'Jane Doe',
    //     photoURL: 'http://www.example.com/12345678/photo.png',
    //     disabled: true,
    // }
    auth.updateUser(uId, data)
      .then((userRecord) => {
        onResponse(true)
        console.log('Successfully updated user', userRecord.toJSON());
      })
      .catch((error) => {
        onResponse(false)
        console.log('Error updating user:', error);
      });
  }

  //////// ORDERS FUNCTIONS
  async loadOrdersData(envoyStatus, lastLoadedDoc, onLoaded) {
    var snapshotDoc = await ordersCollection
      .orderBy("createdAt", "desc")
    if (!isEmptyString(envoyStatus)) {
      snapshotDoc = snapshotDoc.where("envoyStatus", "==", envoyStatus)
    }
    if (lastLoadedDoc) {
      snapshotDoc = await snapshotDoc.startAfter(lastLoadedDoc)
    }
    snapshotDoc = await snapshotDoc.limit(ORDER_LIMIT)
    await snapshotDoc.get().then((docSnap) => {
      let envoyData = [], lastVisDoc = null
      if (docSnap && docSnap.docs.length > 0) {
        lastVisDoc = docSnap.docs[docSnap.docs.length - 1]
        docSnap.forEach((doc) => {
          var envoyDataObj = doc.data()
          envoyDataObj.envoyId = doc.id
          this.getSingleUserData(envoyDataObj.userId, (userData) => {
            envoyDataObj.userData = userData
          })
          envoyData.push(envoyDataObj)
        })
      }
      if (envoyData.length < ORDER_LIMIT) {
        console.log("---envoyData---", envoyData)
        onLoaded(envoyData, lastVisDoc, false)
      } else {
        this.isMoreOrdersExist(envoyStatus, lastVisDoc, (isMoreExist) => {
          onLoaded(envoyData, lastVisDoc, isMoreExist)
        })
      }
    }).catch((error) => {
      console.log("===error===", error)
      onLoaded([], null, false)
    })
  }

  async isMoreOrdersExist(envoyStatus, lastVisDoc, onLoaded) {
    var snapshotDoc = await ordersCollection
      .orderBy("createdAt", "desc")
    if (!isEmptyString(envoyStatus)) {
      snapshotDoc = snapshotDoc.where("envoyStatus", "==", envoyStatus)
    }
    if (lastVisDoc) {
      snapshotDoc = await snapshotDoc.startAfter(lastVisDoc)
    }
    let moreData = await snapshotDoc.limit(1).get()
    onLoaded((moreData && moreData.size >= 1))
  }

  async updateOrdersData(data, envoyId, onCompleted) {
    data.editedAt = new Date()
    let check = await ordersCollection.doc(envoyId).update(data)
    onCompleted(true)
  }

  async deleteOrders(orderId, onCompleted) {
    let check = await ordersCollection.doc(orderId).delete()
    this.updateOrdersStats("decrement", (onresponse) => {
      onCompleted(true)
    })
  }

  async getStatsData(onCompleted) {
    let statsData = {}
    const statsRef = statsCollection
    const docs = await statsRef.get();
    if (docs.empty) {
      onCompleted(null)
    } else {
      docs.forEach(doc => {
        let userData = doc.data()
        if (doc.id == "TOTAL_ORDERS") {
          statsData.totalOrders = doc.data().value
        } else if (doc.id == "TOTAL_USERS") {
          statsData.totalUsers = doc.data().value
        } else if (doc.id == "TOTAL_CATEGORIES") {
          statsData.totalCats = doc.data().value
        }
      })
      onCompleted(statsData)
    }
  }

  async updateOrdersStats(type, onCompleted) {
    let val = 1
    if (type == "decrement") {
      val = -1
    }
    const increment = firebase.firestore.FieldValue.increment(val);
    await statsCollection.doc("TOTAL_ORDERS").update({
      value: increment,
    });
    onCompleted(true)
  }
  async updateUsersStats(type, onCompleted) {
    let val = 1
    if (type == "decrement") {
      val = -1
    }
    const increment = firebase.firestore.FieldValue.increment(val);
    await statsCollection.doc("TOTAL_USERS").update({
      value: increment,
    });
    onCompleted(true)
  }
  async updateCatsStats(type, onCompleted) {
    let val = 1
    if (type == "decrement") {
      val = -1
    }
    const increment = firebase.firestore.FieldValue.increment(val);
    await statsCollection.doc("TOTAL_CATEGORIES").update({
      value: increment,
    });
    onCompleted(true)
  }

  async getUserPlayerIds(userId, onCompleted) {
    const snapshotDoc = usersCollection.doc(userId)
      .collection("DEVICE_TOKEN")
      .orderBy("createdAt", "desc")

    await snapshotDoc.get().then((docSnap) => {
      let snapData = []
      if (docSnap && docSnap.docs.length > 0) {
        docSnap.forEach((doc) => {
          snapData.push(doc.data().playerId)
        })
      }
      onCompleted(snapData)
    }).catch((error) => {
      console.log("===error===", error)
      onCompleted(null)
    })
  }

  // async changeRequestStatus(newRequestStatus, requestData, onCompleted) {
  //     let requestId = requestData.envoyId
  //     let envoyStatus = requestData.envoyStatus
  //     const authUserId = auth().currentUser.uid
  //     var snapshotDoc = await requestsCollection
  //         .doc(authUserId)
  //         .collection(envoyStatus)
  //         .doc(requestId)

  //     const requestDoc = await snapshotDoc.get();
  //     if (requestDoc.empty) {
  //         console.log('No matching documents.');
  //         onCompleted(null)
  //     } else {
  //         let newRequestData = requestDoc.data()
  //         newRequestData.envoyStatus = newRequestStatus
  //         newRequestData.editedAt = currentTimestamp
  //         await requestsCollection.doc(authUserId).collection(newRequestData.envoyStatus)
  //             .add(newRequestData)
  //             .then(async response => {
  //                 await requestsCollection
  //                     .doc(authUserId)
  //                     .collection(envoyStatus)
  //                     .doc(requestId).delete()
  //                 onCompleted(response)
  //             })
  //             .catch((error) => {
  //                 onCompleted(null)
  //                 console.log(error)
  //             })
  //     }
  // }


}