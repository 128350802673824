import {
    LOGIN_USER, LOGIN_USER_SUCCESS, LOGOUT_USER,
    REGISTER_USER, REGISTER_USER_SUCCESS, LOGIN_USER_ERROR,
    REGISTER_USER_ERROR, FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR, RESET_PASSWORD, RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,

    LOGOUT_ADMIN, ADMIN_FORGOT_PASSWORD_ERROR, ADMIN_FORGOT_PASSWORD_SUCCESS,
    ADMIN_FORGOT_PASSWORD, LOGIN_ADMIN_ERROR, LOGIN_ADMIN_SUCCESS, LOGIN_ADMIN
} from '../actions';

export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: { user, history }
});
export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});
export const loginUserError = (message) => ({
    type: LOGIN_USER_ERROR,
    payload: { message }
});

export const forgotPassword = (forgotUserMail, history) => ({
    type: FORGOT_PASSWORD,
    payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
    type: FORGOT_PASSWORD_ERROR,
    payload: { message }
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
    type: RESET_PASSWORD,
    payload: { resetPasswordCode, newPassword, history }
});
export const resetPasswordSuccess = (newPassword) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: newPassword
});
export const resetPasswordError = (message) => ({
    type: RESET_PASSWORD_ERROR,
    payload: { message }
});


export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: { user, history }
})
export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
})
export const registerUserError = (message) => ({
    type: REGISTER_USER_ERROR,
    payload: { message }
})

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history }
});


// ADMIN AUTH
export const loginAdmin = (adminUser, history) => ({
    type: LOGIN_ADMIN,
    payload: { adminUser, history }
});
export const loginAdminSuccess = (adminUser) => ({
    type: LOGIN_ADMIN_SUCCESS,
    payload: adminUser
});
export const loginAdminError = (message, loading) => ({
    type: LOGIN_ADMIN_ERROR,
    payload: { message, loading }
});

export const adminForgotPassword = (forgotUserMail, history) => ({
    type: ADMIN_FORGOT_PASSWORD,
    payload: { forgotUserMail, history }
});
export const adminForgotPasswordSuccess = (forgotUserMail) => ({
    type: ADMIN_FORGOT_PASSWORD_SUCCESS,
    payload: forgotUserMail
});
export const adminForgotPasswordError = (message) => ({
    type: ADMIN_FORGOT_PASSWORD_ERROR,
    payload: { message }
});

export const logoutAdmin = (history) => ({
    type: LOGOUT_ADMIN,
    payload: { history }
});